<template>
  <div>
    <slot name="button" :openPanel="openPanel"> </slot>
    <b-sidebar
      id="sidebar-sales"
      width="450px"
      backdrop-variant="dark"
      backdrop
      right
      shadow
      v-model="visible"
      data-anima="sidebar"
      @hidden="resetUrl"
    >
      <b-container fluid class="container-sidebar">
        <b-row class="panel text-left">
          <b-col>
            <b-overlay :show="loading">
              <sale-details 
                v-if="sale_id"
                ref="sale-preview"
                :distribuitions="distribuitions" 
                :distribuitions_refunded="distribuitions_refunded" 
                :loading="loading" 
                :id_contract="id_contract" 
                :sale_id="sale_id"
                :sale="sale"
                :openType="openType"
                :permissions="permissions"
                :show_edit_email="true"
                @reload="fetchSale"
              />
            </b-overlay>
          </b-col>
        </b-row>
      </b-container>
    </b-sidebar>
  </div>
</template>
<script>
import SaleDetails from "@/components/shared/SaleDetails";
import SaleService from "@/services/resources/SaleService";
import LeadService from "@/services/resources/LeadService";
import ContractService from "@/services/resources/ContractService";
import Cookies from "js-cookie";

const serviceSale = SaleService.build();
const serviceLead = LeadService.build();
const serviceContract = ContractService.build();

export default {
  props: {
    updateQueryParams: {
      type: Function,
      default: () => {},
    },
  },
  name: "SalePreview",
  components: {
    SaleDetails,
  },
  data() {
    return {
      distribuitions: [],
      distribuitions_refunded: [],
      loading: true,
      visible: false,
      id_contract: "",
      sale_id: null,
      sale: {
        avaliation: [],
        product: {
          name: "",
          description: "",
          images: [{ path: "" }],
        },
        client: {
          name: "",
          email: "",
          cellphone: "",
        },
        proposal: {
          name: "",
          description: "",
        },
        refund_solicitations: [],
      },
      openType: null,
      permissions: {},
    };
  },
  methods: {
    getPermissions() {
      if (this.$store.getters.setPanel) {
        var dados = Cookies.get("setPanelPermission");
        if (dados) {
          dados = JSON.parse(dados);
          this.permissions = dados.reduce((obj, item) => {
            obj[item.name] = true;
            return obj;
          }, {});
        }
      }
    },
    resetUrl() {
      if (Object.values(this.$route.query).length) {
        this.$router.push({ query: {} });
      }
      this.updateQueryParams(true); // ao enviar o true, a listagem de vendas não será atualizada
    },
    openPanel(sale_id, openType) {
      this.getPermissions();
      this.openType = openType ? openType : null;
      this.visible = true;
      this.sale_id = sale_id;
      openType === "lead" ? this.fetchLead() : this.fetchSale();
    },
    fetchContract() {
      let data = {
        id:
          "0?contract_id=" +
          this.sale.contract_id +
          "&client_id=" +
          this.sale.client_id,
      };

      serviceContract
        .read(data)
        .then((response) => {
          this.id_contract = response.id || null;
        })
        .catch((err) => {
          console.log(err);
          this.id_contract = null;
        });
    },
    fetchSale() {
      this.sale = {
        product: {
          name: "",
          description: "",
          images: [{ path: "" }],
        },
        client: {
          name: "",
          email: "",
          cellphone: "",
        },
        proposal: {
          name: "",
          description: "",
        },
      };

      this.loading = true;

      let data = {
        id: this.sale_id,
      };

      serviceSale
        .read(data)
        .then((response) => {
          this.sale = response;
          this.distribuitions = response.distribuition
            ? response.distribuition
            : [];
          this.distribuitions_refunded = response.distribution_refunded
            ? response.distribution_refunded
            : [];

          if (this.sale.type === "SUBSCRIPTION") {
            this.fetchContract();
          }

          if (this.sale.shipping_selected) {
            this.sale.shipping_selected = JSON.parse(
              this.sale.shipping_selected
            ).frete;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchLead() {
      this.sale = {
        product: {
          name: "",
          description: "",
          images: [{ path: "" }],
        },
        client: {
          name: "",
          email: "",
          cellphone: "",
        },
        proposal: {
          name: "",
          description: "",
        },
      };

      this.loading = true;

      let data = {
        id: this.sale_id,
      };

      serviceLead
        .read(data)
        .then((response) => {
          console.log(response);
          this.sale = response;
          this.sale.client = response;
          if (this.sale.type === "SUBSCRIPTION") {
            this.fetchContract();
          } else {
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.container-sidebar {
  padding: 30px;
  padding-top: 20px;
}
</style>
