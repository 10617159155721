import Cookies from 'js-cookie'
import store from '@/store/index'
import router from '@/router/index'

export default function (permission) {
  var dados = Cookies.getJSON("setPanelPermission");
  // If the user has no permissions, redirect to the first menu link
  if (dados && store.getters.setPanel) {
    if (!dados.find((x) => x.name === permission)) {

      if (!dados.find((x) => x.name === "DashboardRead")) {
        if (dados.find((x) => x.name === "SaleRead")) {
          // time out because the router is not ready yet
          setTimeout(() =>
            router.replace({ path: "/vendas" })
            , 1000)
        } else if (dados.find((x) => x.name === "ProductRead")) {
          // time out because the router is not ready yet
          setTimeout(() =>
            router.replace({ path: "/produtos" })
            , 1000)
          router.replace({ path: "/produtos" });
        } else if (dados.find((x) => x.name === "CouponRead")) {
          // time out because the router is not ready yet
          setTimeout(() =>
            router.replace({ path: "/cupom" })
            , 1000)

        }
      }
    }
  }
}
